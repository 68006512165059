import {
  cohorts,
  defaultDate
} from './cohortDates'


let cohortNumber = window.location.pathname.split('/')[1]
let todayStr = cohorts[cohortNumber]?.date || defaultDate

export function setTodayStr(cohortNum) {
  todayStr = cohorts[cohortNum]?.date || defaultDate
}


let eventGuid = 0

function createEventId() {
  return String(eventGuid++)
}

export function formatDate(d) {
  return d.toISOString().replace(/T.*$/, '')
}

export function addDays(date, number = 0) {
  let cloneDate = structuredClone(date)
  let someDate = new Date(cloneDate.setDate(cloneDate.getDate() + number));
  return formatDate(someDate)
}

export function reduceDays(date, number = 0) {
  let cloneDate = structuredClone(date)
  let someDate = new Date(cloneDate.setDate(cloneDate.getDate() - number));
  return formatDate(someDate)
}


export const INITIAL_EVENTS_FULL = [
  {
    id: createEventId(),
    title: '1 - Orientation',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Get started with the PMcademy curriculum and set yourself up with the best support system and network to ace your learning',
      url: `https://dashboard.pmcademy.com/docs/`,
    }
  },
  {
    id: createEventId(),
    title: '2.1 - Product Management 101',
    start: addDays(todayStr,1),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "The goal of this checkpoint, and ultimately this entire module, is to give you a high-level overview of the product management field. You'll learn about a product manager's skills, responsibilities, and collaborators, and you'll start thinking about how to discuss these fundamental concepts. Gradually, you'll become familiar with the professional language that would show employers that you're ready for a career in product. In other words, you will start thinking and talking like a product manager.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/what_is_pm`,
    }
  },
  {
    id: createEventId(),
    title: '2.2 Role of Product',
    start: addDays(todayStr,1),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "In this checkpoint, you'll learn how companies are organized, how product fits in, and how those things affect product management work. Product managers are most likely to work at a startup or publicly traded company, so those types of companies will be our focus.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/way_of_product`,
    }
  },
  {
    id: createEventId(),
    title: '2.3 Daily routine as a PM',
    start: addDays(todayStr,2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "This checkpoint will also attempt to describe these tasks and give you a general sense of what you can expect, including the main responsibilities of product managers, what other teams will be doing around you, and how those teams intersect with each other.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/day_as_a_pm`,
    }
  },
  {
    id: createEventId(),
    title: '2.4 Crafting a Presentation',
    start: addDays(todayStr,2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "As a product manager, you'll spend a lot of time communicating to a variety of audiences. Often, that means giving presentations. These may take various forms, from recapping interviews with users to reviewing your upcoming priorities. You will give these presentations, but often they will also be shared as a reference for those that may or may not have attended your presentation.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/presentation`,
    }
  },
  {
    id: createEventId(),
    title: '2.5 Presenting Your Research',
    start: addDays(todayStr,3),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "Presenting in front of people comes naturally to some and is incredibly challenging to others. You might be quite comfortable public speaking about hobbies or other passions, but you get nervous when it's something professional, the group is large, or you fear a project you've worked incredibly hard on may not be well received.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/present_research`,
    }
  },
  {
    id: createEventId(),
    title: '2.6 Delivering a Presentation ',
    start: addDays(todayStr,3),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "In this checkpoint, you'll focus on ways to improve your presentation delivery skills. You'll learn about some useful techniques to help you be more confident, consider the best way to prepare ahead of an important presentation, and receive feedback on a recording of yourself presenting.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/present_delivery`,
    }
  },
  {
    id: createEventId(),
    title: '3.1 Types of Products and Companies',
    start: addDays(todayStr,4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "In this checkpoint, you'll start to explore how product strategy varies based on the type of company, industry, and audience. As you read, ask yourself: what sort of company is the best fit for my personality or past experiences? Am I particularly excited about a certain industry? Use the information in this checkpoint to think about the type of companies you'd like to work for when you graduate from this program",
      url: `https://dashboard.pmcademy.com/docs/modern_business/types`,
    }
  },
  {
    id: createEventId(),
    title: '3.2 Business Models & Ecosystems',
    start: addDays(todayStr,4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "In this checkpoint, you'll start to explore how product strategy varies based on the type of company, industry, and audience. As you read, ask yourself: what sort of company is the best fit for my personality or past experiences? Am I particularly excited about a certain industry? Use the information in this checkpoint to think about the type of companies you'd like to work for when you graduate from this program",
      url: `https://dashboard.pmcademy.com/docs/modern_business/business_models`,
    }
  },
  {
    id: createEventId(),
    title: '3.3 Metrics',
    start: addDays(todayStr,4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "Many products share the same metrics, but a few are different in special ways that you'll need to be aware of. You'll be expected to be fluent in these terms, so the sooner you master them, the sooner you can spend your time focused on the rest of your product work.",
      url: `https://dashboard.pmcademy.com/docs/modern_business/metrics`,
    }
  },
  {
    id: createEventId(),
    title: "3.4 OKR's",
    start: addDays(todayStr,7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "OKRs provide a framework to handle this. Even if your company doesn't use OKRs, you'll be a better product manager if you create your own goals and try to achieve them. Below, you will dig into how you can do this.",
      url: `https://dashboard.pmcademy.com/docs/modern_business/okrs`,
    }
  },
  {
    id: createEventId(),
    title: "3.5 Finding Users",
    start: addDays(todayStr,7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "In this checkpoint, you'll begin to explore how to get in touch with your users and what you need to consider to do so effectively. Understanding who your users are, what they care about, and how to get them to share their perspective with you is going to be a huge part of your success as a PM.",
      url: `https://dashboard.pmcademy.com/docs/modern_business/finding_users`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 1',
    start: addDays(todayStr, 7),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/modern_business/finding_users`,
    },
  },
  {
    id: createEventId(),
    title: "4.1 What is PMF?",
    start: addDays(todayStr,8),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `If every product succeeded, then companies wouldn't need product managers. As you're no doubt aware, many products fail. One of the key reasons for this is the failure to find sufficient product-market fit.

      If you understand the people who use your products, you can create solutions that fit their needs and increase the likelihood of success. In this checkpoint, you'll learn about this concept through the lens of product-market fit.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/what_is_pmf`,
    }
  },
  {
    id: createEventId(),
    title: "4.2 Lean Customer Development",
    start: addDays(todayStr,8),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `If product-market fit is your goal, then you need a few tools to help you get there. Many companies use techniques like the ones you'll learn about here to help them get to product-market fit faster. Many of these methods have been popularized by startups, but now even larger companies are seeing the value of the startup mentality, or lean product development.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/customer_dev`,
    }
  },
  {
    id: createEventId(),
    title: "4.3 MVP",
    start: addDays(todayStr,8),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `MVPs are all the rage in product management. You'll hear the question "What's the MVP?" over and over again at work and in your job interviews. This checkpoint will focus on this essential tool in a product manager's toolkit. You will learn what MVPs are, how to ship them, and how to answer those interview questions about them.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/mvps`,
    }
  },
  {
    id: createEventId(),
    title: "4.4 Interviewing Users",
    start: addDays(todayStr,9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `One of the most important skills you'll develop as a product manager is learning to communicate effectively with a variety of audiences. One major form of communication you'll use is interviews with users and stakeholders. Conducting effective interviews will be crucial to your success, as it will allow you to obtain the information you need to make good decisions about your product and priorities.

      Many elements go into successful interviews, and this checkpoint will review them all. You need to help the interviewee feel comfortable, ask the right questions, listen actively, and take useful notes. When you master these skills, you'll be able to get the most from your interviews.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/user_interview`,
    }
  },
  {
    id: createEventId(),
    title: "4.5 Uncovering Personas and Segments",
    start: addDays(todayStr,9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Once you've interviewed users and tested your hypotheses, it's time to share what you learned with others in your organization. Communicating information is one of your most important responsibilities as a PM. It's also particularly important to ensure that everyone is in agreement about who the product's users are.

      How will you achieve that goal? By creating user personas. Personas are fictional yet realistic representations of your product's key audience or ideal customers. This simple tool can help align teams around their users and bring clarity to problems and goals.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/persona_segment`,
    }
  },
  {
    id: createEventId(),
    title: "5.1 User Flows",
    start: addDays(todayStr,10),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `It's essential for a PM to understand these flows, or the ways users interact with—and get value out of—your product. On some websites, like Wikipedia, users flow endlessly, bouncing from link to link. Other sites have a specific action that they're encouraging you to end with, like making a purchase on Amazon.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/user_flows`,
    }
  },
  {
    id: createEventId(),
    title: "5.2 Funnels",
    start: addDays(todayStr,10),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Now that you've mastered user flows, it's time to dive deeper into the user experience and think about your funnel. The funnel is a widely used concept that explains, visualizes, and helps you analyze your user flow and—importantly—where users are abandoning it. Understanding this concept is a critical step to becoming an effective PM.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/funnels`,
    }
  },
  {
    id: createEventId(),
    title: "5.3 Market Sizing",
    start: addDays(todayStr,11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Product managers need to answer these types of questions while working with imperfect information. For instance, finding the total number of vintage oil can collectors in the United States could be impossible. But you still need something to base your recommendations on. In this checkpoint, you will learn how to turn imperfect information into reasonable guesses.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/market_sizing`,
    }
  },
  {
    id: createEventId(),
    title: "5.4 Competitive Analysis",
    start: addDays(todayStr,11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Most products have competitors. As a product manager, you have a responsibility to understand the competitive landscape. In analyzing other companies and their products, you need to consider many factors, including brand and market awareness, features, customer preferences, and pricing.

      Markets move fast, and you'll need to make decisions quickly. This means you also need ways to quickly analyze your competition. The better you get at this, the better you'll be at making the right decisions when the time comes.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/competitive_analysis`,
    }
  },
  {
    id: createEventId(),
    title: "6. Capstone Project I 🔥",
    start: addDays(todayStr,14),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this capstone, you'll apply what you've learned about business strategy, research, and metrics to a product scenario. You will conduct several analyses, do user research, decide on your recommendation, and create a presentation summarizing your work.`,
      url: `https://dashboard.pmcademy.com/docs/category/6-capstone-project-i-`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 2',
    start: addDays(todayStr, 15),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone_I/synthesize`,
    },
  },
  {
    id: createEventId(),
    title: "7.1 User Experience",
    start: addDays(todayStr,16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By now, you've gained a high-level understanding of product management, the business concepts product managers must understand, and the PM's various roles, tools, and responsibilities. Over the next few modules, you will keep building the skills that will get you hired, with a focus on some of the key activities that make up what is known as product discovery. Some of the skills you will build in this phase of the program are related to user experience (UX) design and research. Other skills you will build will prepare you to do core product work.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/1`,
    }
  },
  {
    id: createEventId(),
    title: "7.2 Design Thinking",
    start: addDays(todayStr,16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Now that you know a bit about design, it's time to get familiar with design thinking. This term means something a bit more specific than thinking about design. Developed at the Stanford design school (or as it is known, the D School), design thinking is an approach (and iterative process) to identifying and solving the problems that people experience.

      Being able to discuss design thinking and use its concepts is very useful for aspiring PMs. Not only can it help you do your job, but it is also an incredibly popular concept among tech companies and other organizations.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/2`,
    }
  },
  {
    id: createEventId(),
    title: "7.3 Storyboarding",
    start: addDays(todayStr,16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `It important for you to understand not only each of the steps and actions your user may take, but also what your user is thinking and feeling throughout their path. Journey mapping and storyboarding are two methods that help you capture all of this information and represent it in an easy-to-understand, visual format.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/3`,
    }
  },
  {
    id: createEventId(),
    title: "7.4 Information Architecture",
    start: addDays(todayStr,17),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Many tech products have a shared problem—how do you organize all the features in a way that makes sense? E-commerce sites have a specific version of this problem—how do you organize all the stuff that you're selling so that it's easy to browse and find a product? And how should you name these features so that users will quickly recognize their function?

      Information architecture is the umbrella term for the method used to address these problems. In this checkpoint, you will learn how to organize content to improve your product's user experience.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/4`,
    }
  },
  {
    id: createEventId(),
    title: "7.5 Surveys & NPS",
    start: addDays(todayStr,17),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `For product managers, surveys are an essential tool for digging deeper into what's really happening with your users and your product. In particular, PMs use a certain kind of survey question that later becomes the net promoter score (NPS). You may recall from the business metrics checkpoint you completed earlier in this program that NPS is often one of your key metrics. In this checkpoint, you'll learn more about how to craft surveys, how to get people to respond to them, and how to use NPS to diagnose your product and understand whether or not you're on the right track.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/5`,
    }
  },
  {
    id: createEventId(),
    title: "7.6 Presenting Data",
    start: addDays(todayStr,18),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this checkpoint, you'll focus on honing basic data analysis and visualization skills. Later in the program, you'll dive deeper into both data analysis (using SQL) and data visualization (using Tableau). Becoming a master of communicating with data will make you a far more effective PM and allow you to convince others of your point of view by clearly showing evidence.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/6`,
    }
  },
  {
    id: createEventId(),
    title: "7.7 Additional Research Methods",
    start: addDays(todayStr,18),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Interviews, surveys, and card-sorting exercises are just a few of the many methods PMs and their UX teams use to research users in the product discovery process. In this checkpoint, you will learn about additional methods, including heuristic and expert evaluations, eye tracking, observation, and diary studies. These narrower, targeted techniques may be the right choice for your specific situation or product. They may lead you to a specific type of insight you may not have gotten any other way.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/7`,
    }
  },
  {
    id: createEventId(),
    title: "8.1 Interaction Design",
    start: addDays(todayStr,21),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Whether you have a UX team or you're designing features yourself, you need to understand the basics of good interaction design. You might think it's easy to throw some buttons and links on a page to make an app, but it's actually very complex and difficult to create an experience that's intuitive and easy to use.

      Thankfully, there are a few basic principles that can quickly improve your design skills. This module will focus on developing these skills, including implementing design psychology, creating forms, and crafting interactive prototypes. By the end of this module, you will be creating and submitting a fully functional interactive prototype of a product.`,
      url: `https://dashboard.pmcademy.com/docs/ui/1`,
    }
  },
  {
    id: createEventId(),
    title: "8.2 Interaction Flows",
    start: addDays(todayStr,21),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will dive into some specific commonly used flows, like registration forms and search functions. You will also learn about flow best practices, the use of user interface (UI) libraries, and how to make products accessible to users of all abilities.`,
      url: `https://dashboard.pmcademy.com/docs/ui/interaction_flows`,
    }
  },
  {
    id: createEventId(),
    title: "8.3 Design Psychology",
    start: addDays(todayStr,21),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Modern product design is about more than creating buttons and form fields. It's also about the psychological and emotional aspects of humans interacting with technology. In the same way that modern marketing uses messaging and imagery to make products appeal to potential customers, designers can apply their own practices to make their products more engaging and desirable.

      In this checkpoint, you will learn about the psychological insights designers apply when creating digital products. You'll also think about ways of applying these insights in your own work.`,
      url: `https://dashboard.pmcademy.com/docs/ui/3`,
    }
  },
  {
    id: createEventId(),
    title: "8.4 Sketching & Wireframing",
    start: addDays(todayStr,22),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In order to create engaging products, you will need to lead your team in the planning of all the detailed interactions that your users will experience. But great interaction designs don't come on the first try. You might go through a dozen iterations before settling on the details of the designs that you will eventually build.`,
      url: `https://dashboard.pmcademy.com/docs/ui/4`,
    }
  },
  {
    id: createEventId(),
    title: "8.5 Mobile vs Desktop",
    start: addDays(todayStr,22),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:

      Explain and apply design best practices and constraints for desktop and mobile`,
      url: `https://dashboard.pmcademy.com/docs/ui/5`,
    }
  },
  {
    id: createEventId(),
    title: "8.6 Forms",
    start: addDays(todayStr,22),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Forms are some of the most common interactive elements you'll lead your team in creating as a product manager. Even though you might think forms are pretty trivial and boring, they are, in fact, some of the most complex pieces of user experience design that you can create.

      Badly designed forms can frustrate users and increase the abandonment rate. Applying the best practices you will learn about in this checkpoint will ensure your forms are clear and effective. When users are able and motivated to complete your forms, your products will be successful as a result.`,
      url: `https://dashboard.pmcademy.com/docs/ui/6`,
    }
  },
  {
    id: createEventId(),
    title: "8.7 Prototypes",
    start: addDays(todayStr,23),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `When designing interfaces and forms, it helps if you can test out how they'll work before they're totally complete. A prototype is a simplified version of the final product you're creating. It may not have all the bells and whistles of the real version, but it's enough for testing and validation. It should demonstrate the core functionalities the product should have without developers spending the time to create those features.

      You can create prototypes for physical or digital products. In this checkpoint, you will learn how you can use tools to create those prototypes, even turning your wireframes and sketches into functioning, clickable experiences.`,
      url: `https://dashboard.pmcademy.com/docs/ui/7`,
    }
  },
  {
    id: createEventId(),
    title: "8.8 Presenting Designs",
    start: addDays(todayStr,23),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `When designing interfaces and forms, it helps if you can test out how they'll work before they're totally complete. A prototype is a simplified version of the final product you're creating. It may not have all the bells and whistles of the real version, but it's enough for testing and validation. It should demonstrate the core functionalities the product should have without developers spending the time to create those features.

      You can create prototypes for physical or digital products. In this checkpoint, you will learn how you can use tools to create those prototypes, even turning your wireframes and sketches into functioning, clickable experiences.`,
      url: `https://dashboard.pmcademy.com/docs/ui/7`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 3',
    start: addDays(todayStr, 23),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/ui/7`,
    },
  },
  {
    id: createEventId(),
    title: "9.1 Roadmap Intro ",
    start: addDays(todayStr,24),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Product managers are responsible for more than just individual features and designs. They also have to plan for the future of their product—anticipate risks and opportunities, figure out where the product is going, and plan for what needs to happen between now and then.

      One way to document these plans is to create a roadmap. A roadmap is a core product management tool. It summarizes what features are planned, in what order your team will develop them, and when each feature is going to launch. In this checkpoint, you will learn more about roadmaps, their purpose and uses, and how to create one.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/1`,
    }
  },
  {
    id: createEventId(),
    title: "9.2 Prioritization Features",
    start: addDays(todayStr,24),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Deciding what to work on next is one of the most important tasks you'll have as a product manager. Whether you're building a roadmap or deciding on the scope of a feature that is going to be part of the roadmap, you'll constantly have to make decisions about what will make the cut and what won't. You will need to consider many factors, from how much value a certain feature will add for your users to how complex it is to build. Often, you will have limited resources (like time, budget, or team members) and will need to make tough choices.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/2`,
    }
  },
  {
    id: createEventId(),
    title: "9.3 Timelines & Tools",
    start: addDays(todayStr,24),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Product managers use a variety of tools to help manage the tasks that their teams need to complete. Roadmapping tools are software tools that let you easily make changes to a roadmap and see how a change in one task will affect your timeline further down. In this checkpoint, you will learn about these tools and why, when, and how to use them.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/3`,
    }
  },
  {
    id: createEventId(),
    title: "9.4 Running Meetings",
    start: addDays(todayStr,25),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `The work you do as a product manager is all about communication, and your work with roadmaps is no exception to this. You will have many conversations with stakeholders as you build your roadmap and will need to artfully manage those relationships, run effective meetings, and handle the feedback you receive. In this checkpoint, you will spend some time developing the communication and collaboration skills essential to your success as a PM.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/4`,
    }
  },
  {
    id: createEventId(),
    title: "9.5 Presenting roadmaps",
    start: addDays(todayStr,25),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Through much hard work and stakeholder conversations, you've finally created a well-prioritized, great-looking roadmap. Now, it's time to communicate your vision to a wider audience than those who helped you put it together. Presenting your roadmap is part storytelling, part business pitch, and part discussion. Doing it well is key to your success as a PM.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/5`,
    }
  },
  {
    id: createEventId(),
    title: "10.1 Usability Tests",
    start: addDays(todayStr,28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `To increase your confidence in your designs—and to find and fix flaws in what you've built—you can test how people engage with your products. That's where usability tests come in. Usability tests can show you where users succeed, stumble, or get stuck as they try to accomplish tasks within your products.`,
      url: `https://dashboard.pmcademy.com/docs/validation/1`,
    }
  },
  {
    id: createEventId(),
    title: "10.2 A/B Testing",
    start: addDays(todayStr,28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Most organizations today value data-driven decision-making. In fact, data is often more important than opinions or other factors that might influence a team's or company's priorities. And typically, the best way to generate reliable data about a product is to enable real people to make real choices.`,
      url: `https://dashboard.pmcademy.com/docs/validation/2`,
    }
  },
  {
    id: createEventId(),
    title: "10.3 A/B Test Results",
    start: addDays(todayStr,28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will learn a bit about the statistical logic behind your test results. This will allow you to better understand the outcomes of your A/B tests, validate their accuracy, and make decisions based on them. You also need to learn how to communicate these concepts and results to others and discuss the validity of your tests with different stakeholders.`,
      url: `https://dashboard.pmcademy.com/docs/validation/3`,
    }
  },
  {
    id: createEventId(),
    title: "11.1 Writing A Resume",
    start: addDays(todayStr,29),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to create a resume that's tailored to your desired job.`,
      url: `https://dashboard.pmcademy.com/docs/validation/3`,
    }
  },
  {
    id: createEventId(),
    title: "11.2 LinkedIn Profile",
    start: addDays(todayStr,29),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to create an effective LinkedIn profile.`,
      url: `https://dashboard.pmcademy.com/docs/persona/2`,
    }
  },
  {
    id: createEventId(),
    title: "12. Capstone Project II 🔥",
    start: addDays(todayStr,30),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this second capstone project, you'll apply the user research, user experience design, and roadmapping skills you've learned in this phase to a scenario involving adding features to an existing product. Your deliverables will include a user survey, notes from interviews with three users, a storyboard, a prototype, a summary of a usability test with at least one user, and a product roadmap. You will bring all of these together in a presentation covering your findings and recommendations.`,
      url: `https://dashboard.pmcademy.com/docs/category/12-capstone-project-ii-`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 4',
    start: addDays(todayStr, 31),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone_II/6`,
    },
  },
  {
    id: createEventId(),
    title: "13.1 Agile Development",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll learn about the core principles of agile development and explore how agile differs from other development methodologies. As a product manager, you'll help organize the development workflow and make sure that your team is completing a variety of tasks. In this work, agile will be one of the key tools in your toolbox.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/1`,
    }
  },
  {
    id: createEventId(),
    title: "13.2 Writing Stories",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Epics and user stories connect high-level roadmap ideas with concrete, executable tasks that can be timeboxed and tracked. These help teams adapt quickly and easily to changes. For instance, user research or technical limitations often cause implementation to differ from what the team originally planned. Highly detailed business requirements—like those you'd find in waterfall development—make it challenging to respond to these changes and all the ripple effects they have on other tasks. But in agile, epics and stories provide a fluid and actionable approach and prevent an endless cycle of complicated revisions to comprehensive documentation.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/2`,
    }
  },
  {
    id: createEventId(),
    title: "13.3 Agile Tools",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll examine a few of the most popular agile tools. You don't need to be an expert, but you should have a working knowledge of these tools and feel comfortable speaking about them with your colleagues. Download and check out the trial versions of the tools reviewed in this checkpoint, or better yet—look for opportunities to work with agile methodologies and tools in your current role or side projects. Having experience with—and opinions about—agile tools will help you demonstrate your professionalism and knowledge about product development in your upcoming job interviews.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/3`,
    }
  },
  {
    id: createEventId(),
    title: "13.4 Backlogs & Sprints",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `A big part of your role as a product manager will be maximizing the use of your developers' time. You will do this by maintaining a prioritized backlog of user stories. Then, you will work to make the stories developer-ready and assign them to your team. This process is known as backlog refinement and sprint planning.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/4`,
    }
  },
  {
    id: createEventId(),
    title: "13.5 Daily Sprint",
    start: addDays(todayStr,35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `As a product manager, you'll be guiding how your team collaborates on a daily basis. How you do this will set the tone for your team's culture and—if done well—earn you the respect of your team members. You'll need to run stand-up meetings, deal with roadblocks and interruptions, and consistently and clearly communicate product objectives, all while managing your time so you can complete your other product management responsibilities.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/5`,
    }
  },
  {
    id: createEventId(),
    title: "13.6 Testing",
    start: addDays(todayStr,35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Validating that features will function as expected is an important step in the development process. On top of making sure everything works as it should (and on multiple devices), you should also make sure that the software knows what to do in an error state. For instance, what happens if a user enters letters in a form field that is set up to only accept numbers? How is it recorded if someone enters the last name "de L'Orange"? What happens if a user repeatedly clicks Buy on the checkout screen? You'll need to anticipate all these different situations and ensure that the product is ready to handle them.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/6`,
    }
  },
  {
    id: createEventId(),
    title: "13.7 Retros & Demos",
    start: addDays(todayStr,35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Wrapping up a sprint involves many details that need to be addressed. If new features were completed, you and your team would want to communicate the details of this accomplishment to your collaborators and celebrate the new functionality. If any issues arose during the sprint—such as team members being late for stand-up meetings or confusion in coordination with internal partners—these should be identified and learned from at each sprint's conclusion.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/7`,
    }
  },
  {
    id: createEventId(),
    title: "14.1 Core Functionality I",
    start: addDays(todayStr,36),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `If you are new to technology, this module will introduce a lot of new information. It's okay to get a little overwhelmed. Take your time reading through the checkpoints, take notes, review the concepts, and be patient with yourself. You may not know a lot about technology yet, but with some hard work, determination, and all the support available to you in this program—you will become a true techie soon enough.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/1`,
    }
  },
  {
    id: createEventId(),
    title: "14.2 Core Functionality II",
    start: addDays(todayStr,36),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Believe it or not, the fundamental structure of the internet has been in place since the 1980s. In the previous checkpoint, you learned about the core protocols of that structure and how they work. In this checkpoint, you'll explore the more advanced features of the internet—additional elements that make the internet more secure, more personalized, and more stable for the billions of users and devices that access it daily. You'll also learn a bit about the features that make the internet a more nice-looking, aesthetically pleasing tool.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/2`,
    }
  },
  {
    id: createEventId(),
    title: "14.3 API's",
    start: addDays(todayStr,36),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Over recent years, APIs have become increasingly more popular in software development, and every product manager is likely to, at some point in their career, come across the question "Can you use an API for that?" To better answer that question and understand the context in which it is asked, it's helpful to know a little about what APIs are and how they function.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/3`,
    }
  },
  {
    id: createEventId(),
    title: "14.4 Frontend-Backend Infrastructure",
    start: addDays(todayStr,37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `You've probably heard the terms frontend and backend, but maybe you are uncertain as to what they mean. In this checkpoint, you will learn about the difference between frontend, backend, and infrastructure. These will tie back to the previous checkpoint; in combination with APIs, these architectural elements allow for a user-friendly interface, an effective processing engine, and a reliable structure for exchanging all the data necessary.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/4`,
    }
  },
  {
    id: createEventId(),
    title: "14.5 Platforms",
    start: addDays(todayStr,37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `The term platform is thrown around a lot. But what is a platform, exactly? Turns out, you regularly engage with platforms of all kinds. Computers, cell phones, and video game systems are all platforms. Invisible structures and tools like databases, programming languages, and systems that power applications are also platforms.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/5`,
    }
  },
  {
    id: createEventId(),
    title: "14.5 Platforms",
    start: addDays(todayStr,38),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `The term platform is thrown around a lot. But what is a platform, exactly? Turns out, you regularly engage with platforms of all kinds. Computers, cell phones, and video game systems are all platforms. Invisible structures and tools like databases, programming languages, and systems that power applications are also platforms.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/5`,
    }
  },
  {
    id: createEventId(),
    title: "14.6 UI Tech",
    start: addDays(todayStr,38),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `The products you work on will likely have a user interface (UI). Some products don't have a user interface, but these are rare. UI technology has evolved from supporting very basic tasks in the 1980s to supporting contemporary tools that offer amazingly intuitive and dynamic experiences today. Nowadays, even highly specialized infrastructure tools are likely to offer a user-friendly interface option in addition to more developer-facing coding interaction.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/6`,
    }
  },
  {
    id: createEventId(),
    title: "14.7 Data",
    start: addDays(todayStr,38),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will learn more about how personalized and predictive technology works. You'll discover what buzzwords like machine learning, artificial intelligence, and data science mean and consider how they might be useful in developing products.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/7`,
    }
  },
  {
    id: createEventId(),
    title: "14.8 Dev Intro",
    start: addDays(todayStr,39),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `To collaborate successfully with your development team, you need to understand developers' day-to-day work. In this checkpoint, you'll learn about software developers' primary activities and responsibilities. With this information under your belt, you'll be better equipped to work with your team efficiently and effectively.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/8`,
    }
  },
  {
    id: createEventId(),
    title: "14.9 Version Control",
    start: addDays(todayStr,39),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:

      Explain basic source code management and version control concepts
      Discuss relevant implications of these concepts on product development`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/9`,
    }
  },
  {
    id: createEventId(),
    title: "15.1 SQL-BI Intro",
    start: addDays(todayStr,42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `You are now beginning to work through some of the more technical, hard skill modules included in this program. These include SQL, Python, and—later on—Tableau. If you are new to these skills, please be prepared to invest more time and effort than usual as you progress through these modules`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/1`,
    }
  },
  {
    id: createEventId(),
    title: "15.2 How Databases Work",
    start: addDays(todayStr,42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `As a product manager, you'll interact with data in a variety of ways. Some companies expect product managers to have an intimate knowledge of data. In other companies, you will work alongside a data scientist or data analyst who will handle your data needs.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/2`,
    }
  },
  {
    id: createEventId(),
    title: "15.3 Setup pgAdmin",
    start: addDays(todayStr,43),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Now that you have a basic understanding of what SQL is and why it's useful, it's time to get your hands dirty! In this checkpoint, you will use a SQL database management tool called PostgreSQL to begin exploring databases. You'll install and navigate the Postgres graphical interface, called pgAdmin, to get a sense of how databases are set up before you begin writing your own queries.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/3`,
    }
  },
  {
    id: createEventId(),
    title: "15.4 SQL Simple Queries",
    start: addDays(todayStr,44),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/4`,
    }
  },
  {
    id: createEventId(),
    title: "15.5 SQL Complex Queries",
    start: addDays(todayStr,45),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/5`,
    }
  },
  {
    id: createEventId(),
    title: "15.6 SQL Joins",
    start: addDays(todayStr,46),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/6`,
    }
  },
  {
    id: createEventId(),
    title: "15.7 SQL Practice Exam",
    start: addDays(todayStr,49),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/7`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 5',
    start: addDays(todayStr, 49),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/sql_bi/7`,
    },
  },
  {
    id: createEventId(),
    title: "16.1 Python For PMs",
    start: addDays(todayStr,50),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `It's time to learn how to code! This may be the module you've been eagerly waiting for since this program began—or the one you've been dreading most of all. If you are already familiar with Python, you may breeze through it, focusing only on submitting assignments. If you are an experienced coder in another language but new to Python, you will have an easier time than most and are sure to find that adding another language to your toolkit is a valuable asset.`,
      url: `https://dashboard.pmcademy.com/docs/python/1`,
    }
  },
  {
    id: createEventId(),
    title: "16.2 Intro to Colaboratory",
    start: addDays(todayStr,50),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `It's time to learn how to code! This may be the module you've been eagerly waiting for since this program began—or the one you've been dreading most of all. If you are already familiar with Python, you may breeze through it, focusing only on submitting assignments. If you are an experienced coder in another language but new to Python, you will have an easier time than most and are sure to find that adding another language to your toolkit is a valuable asset.`,
      url: `https://dashboard.pmcademy.com/docs/python/1`,
    }
  },
  {
    id: createEventId(),
    title: "16.3 Variables",
    start: addDays(todayStr,50),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Now that you have Colab set up, it's time to start learning Python! The first concept you'll tackle is variables. Variables are one of the fundamental building blocks of programming. In this checkpoint, you will learn what variables are, why they're useful, and the different kinds of data types a variable can take on in Python.`,
      url: `https://dashboard.pmcademy.com/docs/python/3`,
    }
  },
  {
    id: createEventId(),
    title: "16.4 Collections",
    start: addDays(todayStr,50),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/4`,
    }
  },
  {
    id: createEventId(),
    title: "16.5 Functions",
    start: addDays(todayStr,51),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/4`,
    }
  },
  {
    id: createEventId(),
    title: "16.6 Strings",
    start: addDays(todayStr,51),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/6`,
    }
  },
  {
    id: createEventId(),
    title: "16.7 Numbers",
    start: addDays(todayStr,51),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/7`,
    }
  },
  {
    id: createEventId(),
    title: "16.8 Conditional Logic",
    start: addDays(todayStr,52),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/8`,
    }
  },
  {
    id: createEventId(),
    title: "16.9 Lists",
    start: addDays(todayStr,52),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/9`,
    }
  },
  {
    id: createEventId(),
    title: "16.10 Dictionaries",
    start: addDays(todayStr,53),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/10`,
    }
  },
  {
    id: createEventId(),
    title: "16.11 Objects & Modules",
    start: addDays(todayStr,53),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/11`,
    }
  },
  {
    id: createEventId(),
    title: "16.12 API's I",
    start: addDays(todayStr,56),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/12`,
    }
  },
  {
    id: createEventId(),
    title: "16.13 API's II",
    start: addDays(todayStr,56),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/13`,
    }
  },
  {
    id: createEventId(),
    title: "16.14 Read Files",
    start: addDays(todayStr,57),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/14`,
    }
  },
  {
    id: createEventId(),
    title: "17.1 Analysing Job Postings",
    start: addDays(todayStr,58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to explain the purpose of job searching.`,
      url: `https://dashboard.pmcademy.com/docs/careers/1`,
    }
  },
  {
    id: createEventId(),
    title: "17.2 Applying For Jobs",
    start: addDays(todayStr,58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to apply to jobs effectively.`,
      url: `https://dashboard.pmcademy.com/docs/careers/2`,
    }
  },
  {
    id: createEventId(),
    title: "17.3 The Art Of Cover Letters",
    start: addDays(todayStr,58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to create effective cover letters.`,
      url: `https://dashboard.pmcademy.com/docs/careers/3`,
    }
  },
  {
    id: createEventId(),
    title: "18. Capstone Project III 🔥",
    start: addDays(todayStr,59),
    end: addDays(todayStr,60),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this capstone, you'll gain experience in some core product management activities. These include documenting product requirements, creating specifications (specs), and writing epics and stories. You'll have a choice between two scenarios; both have a strong technical and data component, requiring you to apply the technical understanding you've developed over the last several modules to a product development challenge.`,
      url: `https://dashboard.pmcademy.com/docs/category/18-capstone-project-iii-`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 6',
    start: addDays(todayStr, 60),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone_III/dev_review`,
    },
  },
  {
    id: createEventId(),
    title: "19.1 Product Marketing",
    start: addDays(todayStr,63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `So far, you've focused on the planning, designing, and building of a product. But what happens when that product is built and functioning? How do you make sure that users know about it? Getting the information out to users is where your marketing team comes in. Marketing is an especially important part of product work when you are launching a new product or a significant update. In these situations, you'll work closely with the marketing team on tasks to promote the product. As a product manager, you will be involved in creating the marketing message to ensure that your product succeeds.`,
      url: `https://dashboard.pmcademy.com/docs/launching/1`,
    }
  },
  {
    id: createEventId(),
    title: "19.2 Profit & Loss",
    start: addDays(todayStr,63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `For most products, the goal of enhancements is either to increase the revenue your company brings in or to reduce the cost of creating or maintaining the product features. This is ultimately what product managers are responsible for; if the PM is successful, those numbers will be heading in the right direction.`,
      url: `https://dashboard.pmcademy.com/docs/launching/2`,
    }
  },
  {
    id: createEventId(),
    title: "19.3 Growth & Acquisition",
    start: addDays(todayStr,64),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Growth is a term used to describe any improvement in the measures of a business's success. You might think growth is the marketing team's job. That's not true. In fact, growth product manager is a new and emerging title in the field of product management. The rise of the growth PM indicates how much companies care about product growth, and their willingness to dedicate product managers to it. Even if you're not a growth PM, growth always matters to a product manager.`,
      url: `https://dashboard.pmcademy.com/docs/launching/3`,
    }
  },
  {
    id: createEventId(),
    title: "19.4 Increasing Growth",
    start: addDays(todayStr,64),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Now that you have a basic idea of how to track product growth, it's time to start thinking in more detail about the actions product managers can take to increase product growth. If your experience is primarily in business strategy or sales, you might even find yourself with the title of growth product manager. This is a role that focuses on the parts of the product experience that are the most critical to gaining new users and getting them to stick with your product.`,
      url: `https://dashboard.pmcademy.com/docs/launching/4`,
    }
  },
  {
    id: createEventId(),
    title: "19.5 Useful Resources",
    start: addDays(todayStr,64),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: ``,
      url: `https://dashboard.pmcademy.com/docs/launching/5`,
    }
  },
  {
    id: createEventId(),
    title: "20.1 Google Sheets ",
    start: addDays(todayStr,65),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you'll learn and practice a few data analysis skills using Google Sheets. The checkpoint focuses on Sheets because Google Suite, which Sheets is included in, is extremely popular with tech companies and is free to access (you just need a Google account). However, the principles you will learn here are highly transferable to other spreadsheet apps.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/1`,
    }
  },
  {
    id: createEventId(),
    title: "20.2 Pivot Tables",
    start: addDays(todayStr,66),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you'll learn how to use pivot tables, from utilizing related functions to filtering and visualizing data. They are a powerful tool that you'll use frequently in your work.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/2`,
    }
  },
  {
    id: createEventId(),
    title: "20.3 Google Analytics",
    start: addDays(todayStr,67),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will learn about Google Analytics, one of the most widely used tools for tracking product usage. You will add a new impressive tool to your skill list—and resume!—and better understand how analytics tools work and how product managers use them.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/3`,
    }
  },
  {
    id: createEventId(),
    title: "20.4 More Tools",
    start: addDays(todayStr,67),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `General analytics tools like Google Analytics are not the only source of information and insights that you can use to understand your products. New, specialized analytics products are being released all the time. And as product managers are always on the lookout for better tools to help understand their users and evaluate their products, it's important that you be familiar with some of the more commonly used options.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/4`,
    }
  },
  {
    id: createEventId(),
    title: "20.5 Visualization",
    start: addDays(todayStr,70),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `You've already learned about basic data visualizations in one of the earlier modules. Now, you will expand on that knowledge and learn how to create advanced data visualizations. These can include multiple scales and dimensions of data; you'll even learn about creating your own bespoke product dashboard. In the next module, you will dive into Tableau, a popular data visualization tool.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/5`,
    }
  },
  {
    id: createEventId(),
    title: "20.6 Ship, Iterate, or Kill",
    start: addDays(todayStr,70),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should have learned how to do the following:
      How to make post-launch decisions to ship, iterate, or kill a feature`,
      url: `https://dashboard.pmcademy.com/docs/analyse/6`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 7',
    start: addDays(todayStr, 70),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/analyse/6`,
    },
  },
  {
    id: createEventId(),
    title: "21.1 BI Tools",
    start: addDays(todayStr,71),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `This checkpoint will focus on business intelligence tools in general—what they're for and how they can help product managers do their job. Learning about this now will ensure that you're ready to use whatever BI tools your company has on the first day of your job.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/1`,
    }
  },
  {
    id: createEventId(),
    title: "21.2 Tableau For PMs",
    start: addDays(todayStr,71),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Tableau is one of the most widely used BI tools, and it is known mostly for its ability to visualize data. As a product manager, knowing Tableau will give you some specific advantages. Most importantly, it will allow you to do your own data analysis.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/2`,
    }
  },
  {
    id: createEventId(),
    title: "21.3 Connecting Data Sources",
    start: addDays(todayStr,71),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `As you learned in the last checkpoint, Tableau connects to all kinds of data. Got an Excel file? It'll import it! Got a CSV file? It can import that too! All data used in Tableau comes from somewhere else. To access this data in Tableau, you'll have to connect to it. In this checkpoint, you'll learn how to connect Tableau to common data sources.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/3`,
    }
  },
  {
    id: createEventId(),
    title: "21.4 Visualizations",
    start: addDays(todayStr,71),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Tableau is well-known not just for its ability to help you do data analysis, but also for its powers of data visualization. In the previous checkpoint, you connected to the Superstore dataset. Now, you'll be using that dataset to build out your first Tableau visualizations.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/4`,
    }
  },
  {
    id: createEventId(),
    title: "21.5 Aggregation",
    start: addDays(todayStr,72),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll focus on common calculated fields that you can set up when preparing data for analysis in Tableau. You'll continue working on the Superstore dataset. This topic includes the concept of aggregation, which you should remember from SQL.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/5`,
    }
  },
  {
    id: createEventId(),
    title: "21.6 Storytelling",
    start: addDays(todayStr,72),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:
      Create a story in Tableau to share data insights`,
      url: `https://dashboard.pmcademy.com/docs/tableau/6`,
    }
  },
  {
    id: createEventId(),
    title: "21.7 Challenge",
    start: addDays(todayStr,73),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `To complete this module and to test your mastery of Tableau, you'll create a series of visualizations. This challenge will be based on a new workbook containing data from Crunchbase about different companies' fundraising, investments, and acquisitions.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/7`,
    }
  },
  {
    id: createEventId(),
    title: "22.1 Compliance",
    start: addDays(todayStr,74),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you'll learn about some of the main legal aspects you should keep in mind, and discuss how product managers work with lawyers and legal teams.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/1`,
    }
  },
  {
    id: createEventId(),
    title: "22.2 Security",
    start: addDays(todayStr,74),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:

      Identify and prepare for potential security issues in technology products`,
      url: `https://dashboard.pmcademy.com/docs/ethical/2`,
    }
  },
  {
    id: createEventId(),
    title: "22.3 User Privacy",
    start: addDays(todayStr,77),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll dig into what privacy means for the products you'll be developing as a PM. You'll look at legal and social issues in technology that impact product decisions, and learn about best practices and the actions you can take to be privacy focused. All of this will make you a better, well-rounded product manager.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/3`,
    }
  },
  {
    id: createEventId(),
    title: "22.4 Ethical Issues",
    start: addDays(todayStr,77),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll learn about some of the ethical issues product managers could face. A deep understanding of these issues will make you a better leader in the product space, and a more well-rounded and thoughtful PM candidate. It will also help you consider all the relevant information if you need to handle difficult ethical choices in your work.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/4`,
    }
  },
  {
    id: createEventId(),
    title: "22.5 Leadership & Stress",
    start: addDays(todayStr,77),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `This checkpoint will focus on strategies that can help you be a better leader to others and manage the stress of the PM role. While these issues might not come up in your upcoming job interviews, they are essential to your long-term success as a product manager. As you near graduation day, it's time to think deeply about your PM career, and prepare for the challenges you might face.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/5`,
    }
  },
  {
    id: createEventId(),
    title: "23.1 Intro to Interviewing",
    start: addDays(todayStr,78),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to explain the purpose of interviews.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/1`,
    }
  },
  {
    id: createEventId(),
    title: "23.2 Behavioral Interview",
    start: addDays(todayStr,78),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to master the behavioral components of interviews.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/2`,
    }
  },
  {
    id: createEventId(),
    title: "23.3 Product management interviews",
    start: addDays(todayStr,78),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to prepare for a product manager interview.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/3`,
    }
  },
  {
    id: createEventId(),
    title: "23.4 Salary Negotiation",
    start: addDays(todayStr,78),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to negotiate a job offer effectively.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/4`,
    }
  },
  {
    id: createEventId(),
    title: "24. Your PM career",
    start: addDays(todayStr,79),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `To create the PM equivalent of an effective portfolio, you'll need a quick and succinct way to communicate your capabilities and previous work. What kind of projects should you share? How will you capture your audience's attention? How long should it be? In this checkpoint, you'll learn the answers to these questions, and plan which project story you want to share. In the following checkpoint, you'll learn where and when to share this work.`,
      url: `https://dashboard.pmcademy.com/docs/category/24-your-pm-career`,
    }
  },
  {
    id: createEventId(),
    title: "25. Capstone Project IV 🔥",
    start: addDays(todayStr,80),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The idea is for each student to focus on a company, product, or industry that would be beneficial for their individual job search. This capstone involves a lot of work, so make sure you choose something that would pay off in an impressive portfolio piece and a good story to tell at your next job interview—maybe even with the company you focus on here!`,
      url: `https://dashboard.pmcademy.com/docs/capstone/1`,
    }
  },
  {
    id: createEventId(),
    title: '25.3 Mentor Call 8',
    start: addDays(todayStr, 81),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone/4`,
    },
  },
  {
    id: createEventId(),
    title: "Graduate",
    start: addDays(todayStr,81),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: ``,
      url: `https://dashboard.pmcademy.com/docs/graduate/1`,
    }
  },
]


export const INITIAL_EVENTS_PART = [
  {
    id: createEventId(),
    title: '1 - Orientation',
    start: addDays(todayStr),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: 'Get started with the PMcademy curriculum and set yourself up with the best support system and network to ace your learning',
      url: `https://dashboard.pmcademy.com/docs/`,
    }
  },
  {
    id: createEventId(),
    title: '2.1 - Product Management 101',
    start: addDays(todayStr,0),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "The goal of this checkpoint, and ultimately this entire module, is to give you a high-level overview of the product management field. You'll learn about a product manager's skills, responsibilities, and collaborators, and you'll start thinking about how to discuss these fundamental concepts. Gradually, you'll become familiar with the professional language that would show employers that you're ready for a career in product. In other words, you will start thinking and talking like a product manager.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/what_is_pm`,
    }
  },
  {
    id: createEventId(),
    title: '2.2 Role of Product',
    start: addDays(todayStr,2),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "In this checkpoint, you'll learn how companies are organized, how product fits in, and how those things affect product management work. Product managers are most likely to work at a startup or publicly traded company, so those types of companies will be our focus.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/way_of_product`,
    }
  },
  {
    id: createEventId(),
    title: '2.3 Daily routine as a PM',
    start: addDays(todayStr,4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "This checkpoint will also attempt to describe these tasks and give you a general sense of what you can expect, including the main responsibilities of product managers, what other teams will be doing around you, and how those teams intersect with each other.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/day_as_a_pm`,
    }
  },
  {
    id: createEventId(),
    title: '2.4 Crafting a Presentation',
    start: addDays(todayStr,4),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "As a product manager, you'll spend a lot of time communicating to a variety of audiences. Often, that means giving presentations. These may take various forms, from recapping interviews with users to reviewing your upcoming priorities. You will give these presentations, but often they will also be shared as a reference for those that may or may not have attended your presentation.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/presentation`,
    }
  },
  {
    id: createEventId(),
    title: '2.5 Presenting Your Research',
    start: addDays(todayStr,7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "Presenting in front of people comes naturally to some and is incredibly challenging to others. You might be quite comfortable public speaking about hobbies or other passions, but you get nervous when it's something professional, the group is large, or you fear a project you've worked incredibly hard on may not be well received.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/present_research`,
    }
  },
  {
    id: createEventId(),
    title: '2.6 Delivering a Presentation ',
    start: addDays(todayStr,7),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "In this checkpoint, you'll focus on ways to improve your presentation delivery skills. You'll learn about some useful techniques to help you be more confident, consider the best way to prepare ahead of an important presentation, and receive feedback on a recording of yourself presenting.",
      url: `https://dashboard.pmcademy.com/docs/way_of_pm/present_delivery`,
    }
  },
  {
    id: createEventId(),
    title: '3.1 Types of Products and Companies',
    start: addDays(todayStr,9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "In this checkpoint, you'll start to explore how product strategy varies based on the type of company, industry, and audience. As you read, ask yourself: what sort of company is the best fit for my personality or past experiences? Am I particularly excited about a certain industry? Use the information in this checkpoint to think about the type of companies you'd like to work for when you graduate from this program",
      url: `https://dashboard.pmcademy.com/docs/modern_business/types`,
    }
  },
  {
    id: createEventId(),
    title: '3.2 Business Models & Ecosystems',
    start: addDays(todayStr,9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "In this checkpoint, you'll start to explore how product strategy varies based on the type of company, industry, and audience. As you read, ask yourself: what sort of company is the best fit for my personality or past experiences? Am I particularly excited about a certain industry? Use the information in this checkpoint to think about the type of companies you'd like to work for when you graduate from this program",
      url: `https://dashboard.pmcademy.com/docs/modern_business/business_models`,
    }
  },
  {
    id: createEventId(),
    title: '3.3 Metrics',
    start: addDays(todayStr,9),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: "Many products share the same metrics, but a few are different in special ways that you'll need to be aware of. You'll be expected to be fluent in these terms, so the sooner you master them, the sooner you can spend your time focused on the rest of your product work.",
      url: `https://dashboard.pmcademy.com/docs/modern_business/metrics`,
    }
  },
  {
    id: createEventId(),
    title: "3.4 OKR's",
    start: addDays(todayStr,11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: "OKRs provide a framework to handle this. Even if your company doesn't use OKRs, you'll be a better product manager if you create your own goals and try to achieve them. Below, you will dig into how you can do this.",
      url: `https://dashboard.pmcademy.com/docs/modern_business/okrs`,
    }
  },
  {
    id: createEventId(),
    title: "3.5 Finding Users",
    start: addDays(todayStr,11),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: "In this checkpoint, you'll begin to explore how to get in touch with your users and what you need to consider to do so effectively. Understanding who your users are, what they care about, and how to get them to share their perspective with you is going to be a huge part of your success as a PM.",
      url: `https://dashboard.pmcademy.com/docs/modern_business/finding_users`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 1',
    start: addDays(todayStr, 11),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/modern_business/finding_users`,
    },
  },
  {
    id: createEventId(),
    title: "4.1 What is PMF?",
    start: addDays(todayStr,14),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `If every product succeeded, then companies wouldn't need product managers. As you're no doubt aware, many products fail. One of the key reasons for this is the failure to find sufficient product-market fit.

      If you understand the people who use your products, you can create solutions that fit their needs and increase the likelihood of success. In this checkpoint, you'll learn about this concept through the lens of product-market fit.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/what_is_pmf`,
    }
  },
  {
    id: createEventId(),
    title: "4.2 Lean Customer Development",
    start: addDays(todayStr,14),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `If product-market fit is your goal, then you need a few tools to help you get there. Many companies use techniques like the ones you'll learn about here to help them get to product-market fit faster. Many of these methods have been popularized by startups, but now even larger companies are seeing the value of the startup mentality, or lean product development.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/customer_dev`,
    }
  },
  {
    id: createEventId(),
    title: "4.3 MVP",
    start: addDays(todayStr,16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `MVPs are all the rage in product management. You'll hear the question "What's the MVP?" over and over again at work and in your job interviews. This checkpoint will focus on this essential tool in a product manager's toolkit. You will learn what MVPs are, how to ship them, and how to answer those interview questions about them.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/mvps`,
    }
  },
  {
    id: createEventId(),
    title: "4.4 Interviewing Users",
    start: addDays(todayStr,16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `One of the most important skills you'll develop as a product manager is learning to communicate effectively with a variety of audiences. One major form of communication you'll use is interviews with users and stakeholders. Conducting effective interviews will be crucial to your success, as it will allow you to obtain the information you need to make good decisions about your product and priorities.

      Many elements go into successful interviews, and this checkpoint will review them all. You need to help the interviewee feel comfortable, ask the right questions, listen actively, and take useful notes. When you master these skills, you'll be able to get the most from your interviews.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/user_interview`,
    }
  },
  {
    id: createEventId(),
    title: "4.5 Uncovering Personas and Segments",
    start: addDays(todayStr,16),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Once you've interviewed users and tested your hypotheses, it's time to share what you learned with others in your organization. Communicating information is one of your most important responsibilities as a PM. It's also particularly important to ensure that everyone is in agreement about who the product's users are.

      How will you achieve that goal? By creating user personas. Personas are fictional yet realistic representations of your product's key audience or ideal customers. This simple tool can help align teams around their users and bring clarity to problems and goals.`,
      url: `https://dashboard.pmcademy.com/docs/pmf/persona_segment`,
    }
  },
  {
    id: createEventId(),
    title: "5.1 User Flows",
    start: addDays(todayStr,18),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `It's essential for a PM to understand these flows, or the ways users interact with—and get value out of—your product. On some websites, like Wikipedia, users flow endlessly, bouncing from link to link. Other sites have a specific action that they're encouraging you to end with, like making a purchase on Amazon.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/user_flows`,
    }
  },
  {
    id: createEventId(),
    title: "5.2 Funnels",
    start: addDays(todayStr,18),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Now that you've mastered user flows, it's time to dive deeper into the user experience and think about your funnel. The funnel is a widely used concept that explains, visualizes, and helps you analyze your user flow and—importantly—where users are abandoning it. Understanding this concept is a critical step to becoming an effective PM.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/funnels`,
    }
  },
  {
    id: createEventId(),
    title: "5.3 Market Sizing",
    start: addDays(todayStr,21),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Product managers need to answer these types of questions while working with imperfect information. For instance, finding the total number of vintage oil can collectors in the United States could be impossible. But you still need something to base your recommendations on. In this checkpoint, you will learn how to turn imperfect information into reasonable guesses.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/market_sizing`,
    }
  },
  {
    id: createEventId(),
    title: "5.4 Competitive Analysis",
    start: addDays(todayStr,21),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Most products have competitors. As a product manager, you have a responsibility to understand the competitive landscape. In analyzing other companies and their products, you need to consider many factors, including brand and market awareness, features, customer preferences, and pricing.

      Markets move fast, and you'll need to make decisions quickly. This means you also need ways to quickly analyze your competition. The better you get at this, the better you'll be at making the right decisions when the time comes.`,
      url: `https://dashboard.pmcademy.com/docs/breaking_down/competitive_analysis`,
    }
  },
  {
    id: createEventId(),
    title: "6. Capstone Project I 🔥",
    start: addDays(todayStr,23),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this capstone, you'll apply what you've learned about business strategy, research, and metrics to a product scenario. You will conduct several analyses, do user research, decide on your recommendation, and create a presentation summarizing your work.`,
      url: `https://dashboard.pmcademy.com/docs/category/6-capstone-project-i-`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 2',
    start: addDays(todayStr, 25),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone_I/synthesize`,
    },
  },
  {
    id: createEventId(),
    title: "7.1 User Experience",
    start: addDays(todayStr,28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By now, you've gained a high-level understanding of product management, the business concepts product managers must understand, and the PM's various roles, tools, and responsibilities. Over the next few modules, you will keep building the skills that will get you hired, with a focus on some of the key activities that make up what is known as product discovery. Some of the skills you will build in this phase of the program are related to user experience (UX) design and research. Other skills you will build will prepare you to do core product work.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/1`,
    }
  },
  {
    id: createEventId(),
    title: "7.2 Design Thinking",
    start: addDays(todayStr,28),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Now that you know a bit about design, it's time to get familiar with design thinking. This term means something a bit more specific than thinking about design. Developed at the Stanford design school (or as it is known, the D School), design thinking is an approach (and iterative process) to identifying and solving the problems that people experience.

      Being able to discuss design thinking and use its concepts is very useful for aspiring PMs. Not only can it help you do your job, but it is also an incredibly popular concept among tech companies and other organizations.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/2`,
    }
  },
  {
    id: createEventId(),
    title: "7.3 Storyboarding",
    start: addDays(todayStr,30),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `It important for you to understand not only each of the steps and actions your user may take, but also what your user is thinking and feeling throughout their path. Journey mapping and storyboarding are two methods that help you capture all of this information and represent it in an easy-to-understand, visual format.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/3`,
    }
  },
  {
    id: createEventId(),
    title: "7.4 Information Architecture",
    start: addDays(todayStr,30),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Many tech products have a shared problem—how do you organize all the features in a way that makes sense? E-commerce sites have a specific version of this problem—how do you organize all the stuff that you're selling so that it's easy to browse and find a product? And how should you name these features so that users will quickly recognize their function?

      Information architecture is the umbrella term for the method used to address these problems. In this checkpoint, you will learn how to organize content to improve your product's user experience.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/4`,
    }
  },
  {
    id: createEventId(),
    title: "7.5 Surveys & NPS",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `For product managers, surveys are an essential tool for digging deeper into what's really happening with your users and your product. In particular, PMs use a certain kind of survey question that later becomes the net promoter score (NPS). You may recall from the business metrics checkpoint you completed earlier in this program that NPS is often one of your key metrics. In this checkpoint, you'll learn more about how to craft surveys, how to get people to respond to them, and how to use NPS to diagnose your product and understand whether or not you're on the right track.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/5`,
    }
  },
  {
    id: createEventId(),
    title: "7.6 Presenting Data",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this checkpoint, you'll focus on honing basic data analysis and visualization skills. Later in the program, you'll dive deeper into both data analysis (using SQL) and data visualization (using Tableau). Becoming a master of communicating with data will make you a far more effective PM and allow you to convince others of your point of view by clearly showing evidence.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/6`,
    }
  },
  {
    id: createEventId(),
    title: "7.7 Additional Research Methods",
    start: addDays(todayStr,32),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Interviews, surveys, and card-sorting exercises are just a few of the many methods PMs and their UX teams use to research users in the product discovery process. In this checkpoint, you will learn about additional methods, including heuristic and expert evaluations, eye tracking, observation, and diary studies. These narrower, targeted techniques may be the right choice for your specific situation or product. They may lead you to a specific type of insight you may not have gotten any other way.`,
      url: `https://dashboard.pmcademy.com/docs/product_discovery/7`,
    }
  },
  {
    id: createEventId(),
    title: "8.1 Interaction Design",
    start: addDays(todayStr,35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Whether you have a UX team or you're designing features yourself, you need to understand the basics of good interaction design. You might think it's easy to throw some buttons and links on a page to make an app, but it's actually very complex and difficult to create an experience that's intuitive and easy to use.

      Thankfully, there are a few basic principles that can quickly improve your design skills. This module will focus on developing these skills, including implementing design psychology, creating forms, and crafting interactive prototypes. By the end of this module, you will be creating and submitting a fully functional interactive prototype of a product.`,
      url: `https://dashboard.pmcademy.com/docs/ui/1`,
    }
  },
  {
    id: createEventId(),
    title: "8.2 Interaction Flows",
    start: addDays(todayStr,35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will dive into some specific commonly used flows, like registration forms and search functions. You will also learn about flow best practices, the use of user interface (UI) libraries, and how to make products accessible to users of all abilities.`,
      url: `https://dashboard.pmcademy.com/docs/ui/interaction_flows`,
    }
  },
  {
    id: createEventId(),
    title: "8.3 Design Psychology",
    start: addDays(todayStr,35),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Modern product design is about more than creating buttons and form fields. It's also about the psychological and emotional aspects of humans interacting with technology. In the same way that modern marketing uses messaging and imagery to make products appeal to potential customers, designers can apply their own practices to make their products more engaging and desirable.

      In this checkpoint, you will learn about the psychological insights designers apply when creating digital products. You'll also think about ways of applying these insights in your own work.`,
      url: `https://dashboard.pmcademy.com/docs/ui/3`,
    }
  },
  {
    id: createEventId(),
    title: "8.4 Sketching & Wireframing",
    start: addDays(todayStr,37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In order to create engaging products, you will need to lead your team in the planning of all the detailed interactions that your users will experience. But great interaction designs don't come on the first try. You might go through a dozen iterations before settling on the details of the designs that you will eventually build.`,
      url: `https://dashboard.pmcademy.com/docs/ui/4`,
    }
  },
  {
    id: createEventId(),
    title: "8.5 Mobile vs Desktop",
    start: addDays(todayStr,37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:

      Explain and apply design best practices and constraints for desktop and mobile`,
      url: `https://dashboard.pmcademy.com/docs/ui/5`,
    }
  },
  {
    id: createEventId(),
    title: "8.6 Forms",
    start: addDays(todayStr,37),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Forms are some of the most common interactive elements you'll lead your team in creating as a product manager. Even though you might think forms are pretty trivial and boring, they are, in fact, some of the most complex pieces of user experience design that you can create.

      Badly designed forms can frustrate users and increase the abandonment rate. Applying the best practices you will learn about in this checkpoint will ensure your forms are clear and effective. When users are able and motivated to complete your forms, your products will be successful as a result.`,
      url: `https://dashboard.pmcademy.com/docs/ui/6`,
    }
  },
  {
    id: createEventId(),
    title: "8.7 Prototypes",
    start: addDays(todayStr,39),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `When designing interfaces and forms, it helps if you can test out how they'll work before they're totally complete. A prototype is a simplified version of the final product you're creating. It may not have all the bells and whistles of the real version, but it's enough for testing and validation. It should demonstrate the core functionalities the product should have without developers spending the time to create those features.

      You can create prototypes for physical or digital products. In this checkpoint, you will learn how you can use tools to create those prototypes, even turning your wireframes and sketches into functioning, clickable experiences.`,
      url: `https://dashboard.pmcademy.com/docs/ui/7`,
    }
  },
  {
    id: createEventId(),
    title: "8.8 Presenting Designs",
    start: addDays(todayStr,39),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `When designing interfaces and forms, it helps if you can test out how they'll work before they're totally complete. A prototype is a simplified version of the final product you're creating. It may not have all the bells and whistles of the real version, but it's enough for testing and validation. It should demonstrate the core functionalities the product should have without developers spending the time to create those features.

      You can create prototypes for physical or digital products. In this checkpoint, you will learn how you can use tools to create those prototypes, even turning your wireframes and sketches into functioning, clickable experiences.`,
      url: `https://dashboard.pmcademy.com/docs/ui/7`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 3',
    start: addDays(todayStr, 39),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/ui/7`,
    },
  },
  {
    id: createEventId(),
    title: "9.1 Roadmap Intro ",
    start: addDays(todayStr,42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Product managers are responsible for more than just individual features and designs. They also have to plan for the future of their product—anticipate risks and opportunities, figure out where the product is going, and plan for what needs to happen between now and then.

      One way to document these plans is to create a roadmap. A roadmap is a core product management tool. It summarizes what features are planned, in what order your team will develop them, and when each feature is going to launch. In this checkpoint, you will learn more about roadmaps, their purpose and uses, and how to create one.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/1`,
    }
  },
  {
    id: createEventId(),
    title: "9.2 Prioritization Features",
    start: addDays(todayStr,42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Deciding what to work on next is one of the most important tasks you'll have as a product manager. Whether you're building a roadmap or deciding on the scope of a feature that is going to be part of the roadmap, you'll constantly have to make decisions about what will make the cut and what won't. You will need to consider many factors, from how much value a certain feature will add for your users to how complex it is to build. Often, you will have limited resources (like time, budget, or team members) and will need to make tough choices.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/2`,
    }
  },
  {
    id: createEventId(),
    title: "9.3 Timelines & Tools",
    start: addDays(todayStr,42),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `Product managers use a variety of tools to help manage the tasks that their teams need to complete. Roadmapping tools are software tools that let you easily make changes to a roadmap and see how a change in one task will affect your timeline further down. In this checkpoint, you will learn about these tools and why, when, and how to use them.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/3`,
    }
  },
  {
    id: createEventId(),
    title: "9.4 Running Meetings",
    start: addDays(todayStr,44),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `The work you do as a product manager is all about communication, and your work with roadmaps is no exception to this. You will have many conversations with stakeholders as you build your roadmap and will need to artfully manage those relationships, run effective meetings, and handle the feedback you receive. In this checkpoint, you will spend some time developing the communication and collaboration skills essential to your success as a PM.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/4`,
    }
  },
  {
    id: createEventId(),
    title: "9.5 Presenting roadmaps",
    start: addDays(todayStr,44),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Through much hard work and stakeholder conversations, you've finally created a well-prioritized, great-looking roadmap. Now, it's time to communicate your vision to a wider audience than those who helped you put it together. Presenting your roadmap is part storytelling, part business pitch, and part discussion. Doing it well is key to your success as a PM.`,
      url: `https://dashboard.pmcademy.com/docs/roadmaps/5`,
    }
  },
  {
    id: createEventId(),
    title: "10.1 Usability Tests",
    start: addDays(todayStr,46),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `To increase your confidence in your designs—and to find and fix flaws in what you've built—you can test how people engage with your products. That's where usability tests come in. Usability tests can show you where users succeed, stumble, or get stuck as they try to accomplish tasks within your products.`,
      url: `https://dashboard.pmcademy.com/docs/validation/1`,
    }
  },
  {
    id: createEventId(),
    title: "10.2 A/B Testing",
    start: addDays(todayStr,46),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Most organizations today value data-driven decision-making. In fact, data is often more important than opinions or other factors that might influence a team's or company's priorities. And typically, the best way to generate reliable data about a product is to enable real people to make real choices.`,
      url: `https://dashboard.pmcademy.com/docs/validation/2`,
    }
  },
  {
    id: createEventId(),
    title: "10.3 A/B Test Results",
    start: addDays(todayStr,46),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will learn a bit about the statistical logic behind your test results. This will allow you to better understand the outcomes of your A/B tests, validate their accuracy, and make decisions based on them. You also need to learn how to communicate these concepts and results to others and discuss the validity of your tests with different stakeholders.`,
      url: `https://dashboard.pmcademy.com/docs/validation/3`,
    }
  },
  {
    id: createEventId(),
    title: "11.1 Writing A Resume",
    start: addDays(todayStr,49),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to create a resume that's tailored to your desired job.`,
      url: `https://dashboard.pmcademy.com/docs/validation/3`,
    }
  },
  {
    id: createEventId(),
    title: "11.2 LinkedIn Profile",
    start: addDays(todayStr,49),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to create an effective LinkedIn profile.`,
      url: `https://dashboard.pmcademy.com/docs/persona/2`,
    }
  },
  {
    id: createEventId(),
    title: "12. Capstone Project II 🔥",
    start: addDays(todayStr,51),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this second capstone project, you'll apply the user research, user experience design, and roadmapping skills you've learned in this phase to a scenario involving adding features to an existing product. Your deliverables will include a user survey, notes from interviews with three users, a storyboard, a prototype, a summary of a usability test with at least one user, and a product roadmap. You will bring all of these together in a presentation covering your findings and recommendations.`,
      url: `https://dashboard.pmcademy.com/docs/category/12-capstone-project-ii-`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 4',
    start: addDays(todayStr, 51),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone_II/6`,
    },
  },
  {
    id: createEventId(),
    title: "13.1 Agile Development",
    start: addDays(todayStr,53),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll learn about the core principles of agile development and explore how agile differs from other development methodologies. As a product manager, you'll help organize the development workflow and make sure that your team is completing a variety of tasks. In this work, agile will be one of the key tools in your toolbox.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/1`,
    }
  },
  {
    id: createEventId(),
    title: "13.2 Writing Stories",
    start: addDays(todayStr,53),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Epics and user stories connect high-level roadmap ideas with concrete, executable tasks that can be timeboxed and tracked. These help teams adapt quickly and easily to changes. For instance, user research or technical limitations often cause implementation to differ from what the team originally planned. Highly detailed business requirements—like those you'd find in waterfall development—make it challenging to respond to these changes and all the ripple effects they have on other tasks. But in agile, epics and stories provide a fluid and actionable approach and prevent an endless cycle of complicated revisions to comprehensive documentation.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/2`,
    }
  },
  {
    id: createEventId(),
    title: "13.3 Agile Tools",
    start: addDays(todayStr,53),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll examine a few of the most popular agile tools. You don't need to be an expert, but you should have a working knowledge of these tools and feel comfortable speaking about them with your colleagues. Download and check out the trial versions of the tools reviewed in this checkpoint, or better yet—look for opportunities to work with agile methodologies and tools in your current role or side projects. Having experience with—and opinions about—agile tools will help you demonstrate your professionalism and knowledge about product development in your upcoming job interviews.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/3`,
    }
  },
  {
    id: createEventId(),
    title: "13.4 Backlogs & Sprints",
    start: addDays(todayStr,56),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `A big part of your role as a product manager will be maximizing the use of your developers' time. You will do this by maintaining a prioritized backlog of user stories. Then, you will work to make the stories developer-ready and assign them to your team. This process is known as backlog refinement and sprint planning.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/4`,
    }
  },
  {
    id: createEventId(),
    title: "13.5 Daily Sprint",
    start: addDays(todayStr,56),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `As a product manager, you'll be guiding how your team collaborates on a daily basis. How you do this will set the tone for your team's culture and—if done well—earn you the respect of your team members. You'll need to run stand-up meetings, deal with roadblocks and interruptions, and consistently and clearly communicate product objectives, all while managing your time so you can complete your other product management responsibilities.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/5`,
    }
  },
  {
    id: createEventId(),
    title: "13.6 Testing",
    start: addDays(todayStr,58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Validating that features will function as expected is an important step in the development process. On top of making sure everything works as it should (and on multiple devices), you should also make sure that the software knows what to do in an error state. For instance, what happens if a user enters letters in a form field that is set up to only accept numbers? How is it recorded if someone enters the last name "de L'Orange"? What happens if a user repeatedly clicks Buy on the checkout screen? You'll need to anticipate all these different situations and ensure that the product is ready to handle them.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/6`,
    }
  },
  {
    id: createEventId(),
    title: "13.7 Retros & Demos",
    start: addDays(todayStr,58),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Wrapping up a sprint involves many details that need to be addressed. If new features were completed, you and your team would want to communicate the details of this accomplishment to your collaborators and celebrate the new functionality. If any issues arose during the sprint—such as team members being late for stand-up meetings or confusion in coordination with internal partners—these should be identified and learned from at each sprint's conclusion.`,
      url: `https://dashboard.pmcademy.com/docs/new_products/7`,
    }
  },
  {
    id: createEventId(),
    title: "14.1 Core Functionality I",
    start: addDays(todayStr,60),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `If you are new to technology, this module will introduce a lot of new information. It's okay to get a little overwhelmed. Take your time reading through the checkpoints, take notes, review the concepts, and be patient with yourself. You may not know a lot about technology yet, but with some hard work, determination, and all the support available to you in this program—you will become a true techie soon enough.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/1`,
    }
  },
  {
    id: createEventId(),
    title: "14.2 Core Functionality II",
    start: addDays(todayStr,60),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Believe it or not, the fundamental structure of the internet has been in place since the 1980s. In the previous checkpoint, you learned about the core protocols of that structure and how they work. In this checkpoint, you'll explore the more advanced features of the internet—additional elements that make the internet more secure, more personalized, and more stable for the billions of users and devices that access it daily. You'll also learn a bit about the features that make the internet a more nice-looking, aesthetically pleasing tool.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/2`,
    }
  },
  {
    id: createEventId(),
    title: "14.3 API's",
    start: addDays(todayStr,60),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Over recent years, APIs have become increasingly more popular in software development, and every product manager is likely to, at some point in their career, come across the question "Can you use an API for that?" To better answer that question and understand the context in which it is asked, it's helpful to know a little about what APIs are and how they function.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/3`,
    }
  },
  {
    id: createEventId(),
    title: "14.4 Frontend-Backend Infrastructure",
    start: addDays(todayStr,63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `You've probably heard the terms frontend and backend, but maybe you are uncertain as to what they mean. In this checkpoint, you will learn about the difference between frontend, backend, and infrastructure. These will tie back to the previous checkpoint; in combination with APIs, these architectural elements allow for a user-friendly interface, an effective processing engine, and a reliable structure for exchanging all the data necessary.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/4`,
    }
  },
  {
    id: createEventId(),
    title: "14.5 Platforms",
    start: addDays(todayStr,63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `The term platform is thrown around a lot. But what is a platform, exactly? Turns out, you regularly engage with platforms of all kinds. Computers, cell phones, and video game systems are all platforms. Invisible structures and tools like databases, programming languages, and systems that power applications are also platforms.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/5`,
    }
  },
  {
    id: createEventId(),
    title: "14.5 Platforms",
    start: addDays(todayStr,63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `The term platform is thrown around a lot. But what is a platform, exactly? Turns out, you regularly engage with platforms of all kinds. Computers, cell phones, and video game systems are all platforms. Invisible structures and tools like databases, programming languages, and systems that power applications are also platforms.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/5`,
    }
  },
  {
    id: createEventId(),
    title: "14.6 UI Tech",
    start: addDays(todayStr,63),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `The products you work on will likely have a user interface (UI). Some products don't have a user interface, but these are rare. UI technology has evolved from supporting very basic tasks in the 1980s to supporting contemporary tools that offer amazingly intuitive and dynamic experiences today. Nowadays, even highly specialized infrastructure tools are likely to offer a user-friendly interface option in addition to more developer-facing coding interaction.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/6`,
    }
  },
  {
    id: createEventId(),
    title: "14.7 Data",
    start: addDays(todayStr,65),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will learn more about how personalized and predictive technology works. You'll discover what buzzwords like machine learning, artificial intelligence, and data science mean and consider how they might be useful in developing products.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/7`,
    }
  },
  {
    id: createEventId(),
    title: "14.8 Dev Intro",
    start: addDays(todayStr,65),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `To collaborate successfully with your development team, you need to understand developers' day-to-day work. In this checkpoint, you'll learn about software developers' primary activities and responsibilities. With this information under your belt, you'll be better equipped to work with your team efficiently and effectively.`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/8`,
    }
  },
  {
    id: createEventId(),
    title: "14.9 Version Control",
    start: addDays(todayStr,65),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:

      Explain basic source code management and version control concepts
      Discuss relevant implications of these concepts on product development`,
      url: `https://dashboard.pmcademy.com/docs/tech_fundamentals/9`,
    }
  },
  {
    id: createEventId(),
    title: "15.1 SQL-BI Intro",
    start: addDays(todayStr,67),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `You are now beginning to work through some of the more technical, hard skill modules included in this program. These include SQL, Python, and—later on—Tableau. If you are new to these skills, please be prepared to invest more time and effort than usual as you progress through these modules`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/1`,
    }
  },
  {
    id: createEventId(),
    title: "15.2 How Databases Work",
    start: addDays(todayStr,67),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `As a product manager, you'll interact with data in a variety of ways. Some companies expect product managers to have an intimate knowledge of data. In other companies, you will work alongside a data scientist or data analyst who will handle your data needs.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/2`,
    }
  },
  {
    id: createEventId(),
    title: "15.3 Setup pgAdmin",
    start: addDays(todayStr,67),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Now that you have a basic understanding of what SQL is and why it's useful, it's time to get your hands dirty! In this checkpoint, you will use a SQL database management tool called PostgreSQL to begin exploring databases. You'll install and navigate the Postgres graphical interface, called pgAdmin, to get a sense of how databases are set up before you begin writing your own queries.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/3`,
    }
  },
  {
    id: createEventId(),
    title: "15.4 SQL Simple Queries",
    start: addDays(todayStr,70),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/4`,
    }
  },
  {
    id: createEventId(),
    title: "15.5 SQL Complex Queries",
    start: addDays(todayStr,72),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/5`,
    }
  },
  {
    id: createEventId(),
    title: "15.6 SQL Joins",
    start: addDays(todayStr,74),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/6`,
    }
  },
  {
    id: createEventId(),
    title: "15.7 SQL Practice Exam",
    start: addDays(todayStr,77),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `🚧 The lesson is under constrution.`,
      url: `https://dashboard.pmcademy.com/docs/sql_bi/7`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 5',
    start: addDays(todayStr, 77),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/sql_bi/7`,
    },
  },
  {
    id: createEventId(),
    title: "16.1 Python For PMs",
    start: addDays(todayStr,79),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `It's time to learn how to code! This may be the module you've been eagerly waiting for since this program began—or the one you've been dreading most of all. If you are already familiar with Python, you may breeze through it, focusing only on submitting assignments. If you are an experienced coder in another language but new to Python, you will have an easier time than most and are sure to find that adding another language to your toolkit is a valuable asset.`,
      url: `https://dashboard.pmcademy.com/docs/python/1`,
    }
  },
  {
    id: createEventId(),
    title: "16.2 Intro to Colaboratory",
    start: addDays(todayStr,79),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `It's time to learn how to code! This may be the module you've been eagerly waiting for since this program began—or the one you've been dreading most of all. If you are already familiar with Python, you may breeze through it, focusing only on submitting assignments. If you are an experienced coder in another language but new to Python, you will have an easier time than most and are sure to find that adding another language to your toolkit is a valuable asset.`,
      url: `https://dashboard.pmcademy.com/docs/python/1`,
    }
  },
  {
    id: createEventId(),
    title: "16.3 Variables",
    start: addDays(todayStr,81),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `Now that you have Colab set up, it's time to start learning Python! The first concept you'll tackle is variables. Variables are one of the fundamental building blocks of programming. In this checkpoint, you will learn what variables are, why they're useful, and the different kinds of data types a variable can take on in Python.`,
      url: `https://dashboard.pmcademy.com/docs/python/3`,
    }
  },
  {
    id: createEventId(),
    title: "16.4 Collections",
    start: addDays(todayStr,84),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/4`,
    }
  },
  {
    id: createEventId(),
    title: "16.5 Functions",
    start: addDays(todayStr,84),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/4`,
    }
  },
  {
    id: createEventId(),
    title: "16.6 Strings",
    start: addDays(todayStr,86),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/6`,
    }
  },
  {
    id: createEventId(),
    title: "16.7 Numbers",
    start: addDays(todayStr,86),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/7`,
    }
  },
  {
    id: createEventId(),
    title: "16.8 Conditional Logic",
    start: addDays(todayStr,88),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/8`,
    }
  },
  {
    id: createEventId(),
    title: "16.9 Lists",
    start: addDays(todayStr,88),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/9`,
    }
  },
  {
    id: createEventId(),
    title: "16.10 Dictionaries",
    start: addDays(todayStr,91),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/10`,
    }
  },
  {
    id: createEventId(),
    title: "16.11 Objects & Modules",
    start: addDays(todayStr,93),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/11`,
    }
  },
  {
    id: createEventId(),
    title: "16.12 API's I",
    start: addDays(todayStr,93),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/12`,
    }
  },
  {
    id: createEventId(),
    title: "16.13 API's II",
    start: addDays(todayStr,95),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/13`,
    }
  },
  {
    id: createEventId(),
    title: "16.14 Read Files",
    start: addDays(todayStr,95),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass optional',
    extendedProps: {
      desc: `🚧 The lesson is being updated as you read.`,
      url: `https://dashboard.pmcademy.com/docs/python/14`,
    }
  },
  {
    id: createEventId(),
    title: "17.1 Analysing Job Postings",
    start: addDays(todayStr,98),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to explain the purpose of job searching.`,
      url: `https://dashboard.pmcademy.com/docs/careers/1`,
    }
  },
  {
    id: createEventId(),
    title: "17.2 Applying For Jobs",
    start: addDays(todayStr,98),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to apply to jobs effectively.`,
      url: `https://dashboard.pmcademy.com/docs/careers/2`,
    }
  },
  {
    id: createEventId(),
    title: "17.3 The Art Of Cover Letters",
    start: addDays(todayStr,98),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to create effective cover letters.`,
      url: `https://dashboard.pmcademy.com/docs/careers/3`,
    }
  },
  {
    id: createEventId(),
    title: "18. Capstone Project III 🔥",
    start: addDays(todayStr,100),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `In this capstone, you'll gain experience in some core product management activities. These include documenting product requirements, creating specifications (specs), and writing epics and stories. You'll have a choice between two scenarios; both have a strong technical and data component, requiring you to apply the technical understanding you've developed over the last several modules to a product development challenge.`,
      url: `https://dashboard.pmcademy.com/docs/category/18-capstone-project-iii-`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 6',
    start: addDays(todayStr, 100),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone_III/dev_review`,
    },
  },
  {
    id: createEventId(),
    title: "19.1 Product Marketing",
    start: addDays(todayStr,102),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `So far, you've focused on the planning, designing, and building of a product. But what happens when that product is built and functioning? How do you make sure that users know about it? Getting the information out to users is where your marketing team comes in. Marketing is an especially important part of product work when you are launching a new product or a significant update. In these situations, you'll work closely with the marketing team on tasks to promote the product. As a product manager, you will be involved in creating the marketing message to ensure that your product succeeds.`,
      url: `https://dashboard.pmcademy.com/docs/launching/1`,
    }
  },
  {
    id: createEventId(),
    title: "19.2 Profit & Loss",
    start: addDays(todayStr,102),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `For most products, the goal of enhancements is either to increase the revenue your company brings in or to reduce the cost of creating or maintaining the product features. This is ultimately what product managers are responsible for; if the PM is successful, those numbers will be heading in the right direction.`,
      url: `https://dashboard.pmcademy.com/docs/launching/2`,
    }
  },
  {
    id: createEventId(),
    title: "19.3 Growth & Acquisition",
    start: addDays(todayStr,102),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Growth is a term used to describe any improvement in the measures of a business's success. You might think growth is the marketing team's job. That's not true. In fact, growth product manager is a new and emerging title in the field of product management. The rise of the growth PM indicates how much companies care about product growth, and their willingness to dedicate product managers to it. Even if you're not a growth PM, growth always matters to a product manager.`,
      url: `https://dashboard.pmcademy.com/docs/launching/3`,
    }
  },
  {
    id: createEventId(),
    title: "19.4 Increasing Growth",
    start: addDays(todayStr,105),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Now that you have a basic idea of how to track product growth, it's time to start thinking in more detail about the actions product managers can take to increase product growth. If your experience is primarily in business strategy or sales, you might even find yourself with the title of growth product manager. This is a role that focuses on the parts of the product experience that are the most critical to gaining new users and getting them to stick with your product.`,
      url: `https://dashboard.pmcademy.com/docs/launching/4`,
    }
  },
  {
    id: createEventId(),
    title: "19.5 Useful Resources",
    start: addDays(todayStr,105),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: ``,
      url: `https://dashboard.pmcademy.com/docs/launching/5`,
    }
  },
  {
    id: createEventId(),
    title: "20.1 Google Sheets ",
    start: addDays(todayStr,107),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you'll learn and practice a few data analysis skills using Google Sheets. The checkpoint focuses on Sheets because Google Suite, which Sheets is included in, is extremely popular with tech companies and is free to access (you just need a Google account). However, the principles you will learn here are highly transferable to other spreadsheet apps.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/1`,
    }
  },
  {
    id: createEventId(),
    title: "20.2 Pivot Tables",
    start: addDays(todayStr,109),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you'll learn how to use pivot tables, from utilizing related functions to filtering and visualizing data. They are a powerful tool that you'll use frequently in your work.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/2`,
    }
  },
  {
    id: createEventId(),
    title: "20.3 Google Analytics",
    start: addDays(todayStr,109),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you will learn about Google Analytics, one of the most widely used tools for tracking product usage. You will add a new impressive tool to your skill list—and resume!—and better understand how analytics tools work and how product managers use them.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/3`,
    }
  },
  {
    id: createEventId(),
    title: "20.4 More Tools",
    start: addDays(todayStr,112),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `General analytics tools like Google Analytics are not the only source of information and insights that you can use to understand your products. New, specialized analytics products are being released all the time. And as product managers are always on the lookout for better tools to help understand their users and evaluate their products, it's important that you be familiar with some of the more commonly used options.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/4`,
    }
  },
  {
    id: createEventId(),
    title: "20.5 Visualization",
    start: addDays(todayStr,112),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `You've already learned about basic data visualizations in one of the earlier modules. Now, you will expand on that knowledge and learn how to create advanced data visualizations. These can include multiple scales and dimensions of data; you'll even learn about creating your own bespoke product dashboard. In the next module, you will dive into Tableau, a popular data visualization tool.`,
      url: `https://dashboard.pmcademy.com/docs/analyse/5`,
    }
  },
  {
    id: createEventId(),
    title: "20.6 Ship, Iterate, or Kill",
    start: addDays(todayStr,114),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should have learned how to do the following:
      How to make post-launch decisions to ship, iterate, or kill a feature`,
      url: `https://dashboard.pmcademy.com/docs/analyse/6`,
    }
  },
  {
    id: createEventId(),
    title: 'Mentor Call 7',
    start: addDays(todayStr, 114),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/analyse/6`,
    },
  },
  {
    id: createEventId(),
    title: "21.1 BI Tools",
    start: addDays(todayStr,116),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `This checkpoint will focus on business intelligence tools in general—what they're for and how they can help product managers do their job. Learning about this now will ensure that you're ready to use whatever BI tools your company has on the first day of your job.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/1`,
    }
  },
  {
    id: createEventId(),
    title: "21.2 Tableau For PMs",
    start: addDays(todayStr,116),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `Tableau is one of the most widely used BI tools, and it is known mostly for its ability to visualize data. As a product manager, knowing Tableau will give you some specific advantages. Most importantly, it will allow you to do your own data analysis.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/2`,
    }
  },
  {
    id: createEventId(),
    title: "21.3 Connecting Data Sources",
    start: addDays(todayStr,116),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `As you learned in the last checkpoint, Tableau connects to all kinds of data. Got an Excel file? It'll import it! Got a CSV file? It can import that too! All data used in Tableau comes from somewhere else. To access this data in Tableau, you'll have to connect to it. In this checkpoint, you'll learn how to connect Tableau to common data sources.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/3`,
    }
  },
  {
    id: createEventId(),
    title: "21.4 Visualizations",
    start: addDays(todayStr,119),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `Tableau is well-known not just for its ability to help you do data analysis, but also for its powers of data visualization. In the previous checkpoint, you connected to the Superstore dataset. Now, you'll be using that dataset to build out your first Tableau visualizations.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/4`,
    }
  },
  {
    id: createEventId(),
    title: "21.5 Aggregation",
    start: addDays(todayStr,121),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll focus on common calculated fields that you can set up when preparing data for analysis in Tableau. You'll continue working on the Superstore dataset. This topic includes the concept of aggregation, which you should remember from SQL.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/5`,
    }
  },
  {
    id: createEventId(),
    title: "21.6 Storytelling",
    start: addDays(todayStr,121),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:
      Create a story in Tableau to share data insights`,
      url: `https://dashboard.pmcademy.com/docs/tableau/6`,
    }
  },
  {
    id: createEventId(),
    title: "21.7 Challenge",
    start: addDays(todayStr,123),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `To complete this module and to test your mastery of Tableau, you'll create a series of visualizations. This challenge will be based on a new workbook containing data from Crunchbase about different companies' fundraising, investments, and acquisitions.`,
      url: `https://dashboard.pmcademy.com/docs/tableau/7`,
    }
  },
  {
    id: createEventId(),
    title: "22.1 Compliance",
    start: addDays(todayStr,126),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `In this checkpoint, you'll learn about some of the main legal aspects you should keep in mind, and discuss how product managers work with lawyers and legal teams.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/1`,
    }
  },
  {
    id: createEventId(),
    title: "22.2 Security",
    start: addDays(todayStr,126),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to do the following:

      Identify and prepare for potential security issues in technology products`,
      url: `https://dashboard.pmcademy.com/docs/ethical/2`,
    }
  },
  {
    id: createEventId(),
    title: "22.3 User Privacy",
    start: addDays(todayStr,126),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll dig into what privacy means for the products you'll be developing as a PM. You'll look at legal and social issues in technology that impact product decisions, and learn about best practices and the actions you can take to be privacy focused. All of this will make you a better, well-rounded product manager.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/3`,
    }
  },
  {
    id: createEventId(),
    title: "22.4 Ethical Issues",
    start: addDays(todayStr,128),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `In this checkpoint, you'll learn about some of the ethical issues product managers could face. A deep understanding of these issues will make you a better leader in the product space, and a more well-rounded and thoughtful PM candidate. It will also help you consider all the relevant information if you need to handle difficult ethical choices in your work.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/4`,
    }
  },
  {
    id: createEventId(),
    title: "22.5 Leadership & Stress",
    start: addDays(todayStr,128),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `This checkpoint will focus on strategies that can help you be a better leader to others and manage the stress of the PM role. While these issues might not come up in your upcoming job interviews, they are essential to your long-term success as a product manager. As you near graduation day, it's time to think deeply about your PM career, and prepare for the challenges you might face.`,
      url: `https://dashboard.pmcademy.com/docs/ethical/5`,
    }
  },
  {
    id: createEventId(),
    title: "23.1 Intro to Interviewing",
    start: addDays(todayStr,130),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to explain the purpose of interviews.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/1`,
    }
  },
  {
    id: createEventId(),
    title: "23.2 Behavioral Interview",
    start: addDays(todayStr,130),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to master the behavioral components of interviews.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/2`,
    }
  },
  {
    id: createEventId(),
    title: "23.3 Product management interviews",
    start: addDays(todayStr,133),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to prepare for a product manager interview.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/3`,
    }
  },
  {
    id: createEventId(),
    title: "23.4 Salary Negotiation",
    start: addDays(todayStr,133),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `By the end of this checkpoint, you should be able to negotiate a job offer effectively.`,
      url: `https://dashboard.pmcademy.com/docs/careers_interview/4`,
    }
  },
  {
    id: createEventId(),
    title: "24. Your PM career",
    start: addDays(todayStr,133),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass normal',
    extendedProps: {
      desc: `To create the PM equivalent of an effective portfolio, you'll need a quick and succinct way to communicate your capabilities and previous work. What kind of projects should you share? How will you capture your audience's attention? How long should it be? In this checkpoint, you'll learn the answers to these questions, and plan which project story you want to share. In the following checkpoint, you'll learn where and when to share this work.`,
      url: `https://dashboard.pmcademy.com/docs/category/24-your-pm-career`,
    }
  },
  {
    id: createEventId(),
    title: "25. Capstone Project IV 🔥",
    start: addDays(todayStr,135),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: `The idea is for each student to focus on a company, product, or industry that would be beneficial for their individual job search. This capstone involves a lot of work, so make sure you choose something that would pay off in an impressive portfolio piece and a good story to tell at your next job interview—maybe even with the company you focus on here!`,
      url: `https://dashboard.pmcademy.com/docs/capstone/1`,
    }
  },
  {
    id: createEventId(),
    title: '25.3 Mentor Call 8',
    start: addDays(todayStr, 137),
    allDay: true,
    isComplete: false,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the pmcademy link if necessary for them to know what doubts you have',
      url: `https://dashboard.pmcademy.com/docs/capstone/4`,
    },
  },
  {
    id: createEventId(),
    title: "Graduate",
    start: addDays(todayStr,137),
    allDay: true,
    isComplete: false,
    classNames: 'eventClass graded',
    extendedProps: {
      desc: ``,
      url: `https://dashboard.pmcademy.com/docs/graduate/1`,
    }
  },
]